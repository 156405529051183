<template>
    <topBanner/>
    <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img :src="getImg(specificData.image)" alt="img">
            </div> 
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title"> 
              <h3 class="title">{{ specificData.title }}</h3>
            </div>
            <div class="sigma_about-content">
              <p v-for="item in specificData.desc" :key="item.id">{{ item.row }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row table-sec pt-5">
         <div class="col-lg-12">
          <p class="table-heading">20' Bulk Container</p>
          <table class="table">
            <tr>
              <td class="text-center bg-gray">Dimensions*</td>
              <td class="text-center bg-gray">Length</td>
              <td class="text-center bg-gray">Width</td>
              <td class="text-center bg-gray">Height</td>
            </tr>
            <tr>
              <td class="bg-gray">External</td>
              <td class="text-center">6096 mm</td>
              <td class="text-center">2362 mm</td>
              <td class="text-center">2590 mm</td>
            </tr>
            <tr>
              <td class="bg-gray">Internal</td>
              <td class="text-center">5934 mm</td>
              <td class="text-center">2358 mm</td>
              <td class="text-center">2340 mm</td>
            </tr>
            <tr>
              <td class="bg-gray">Door Openings</td>
              <td></td>
              <td class="text-center">2335 mm</td>
              <td class="text-center">2292 mm</td>
            </tr>
            <tr>
              <td colspan="3" class="text-center bg-gray">Weights*</td> 
              <td rowspan="2" class="text-center bg-gray align-middle">Capacity*</td>
            </tr>
            <tr>
              <td class="text-center bg-gray">Max. Gross</td>
              <td class="text-center bg-gray">Tare</td>
              <td class="text-center bg-gray">Max. Payload</td>
            </tr>
            <tr>
              <td class="text-center">24000 kg</td>
              <td class="text-center">2450 kg</td>
              <td class="text-center">21550 kg</td>
              <td class="text-center">32.9 cub.m</td>
            </tr>
          </table>
         </div>
      </div>
      <p class="warning-text">{{ specificData.warning }}</p>
    </div>
  </div>
</template>

<script>
import data from '../../Data/data.json'
import topBanner from '../../components/innerpagestopbanner/innerTop.vue'
export default {
    components: {
        topBanner
    },
    data(){
        return{
          specificData: ''
        }
    },
    created(){
      this.specificData = data.containertypes_specifications.products[8]
    },
    methods: {
      getImg(pic){
        if(pic){
          return require('../../assets/img/typesandspecifications/' + pic)
        } else{
          return null
        }
      }
    }
}
</script>