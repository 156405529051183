<template>
    <topBanner/>
    <typeandspecifications/>
</template>

<script>
import topBanner from '../components/innerpagestopbanner/innerTop.vue'
import typeandspecifications from '../components/typesandspecification.vue'
export default {
    components: {
        topBanner,
        typeandspecifications
    },
    data(){
        return{}
    }
}
</script>