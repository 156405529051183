<template>
  <topBanner />
  <!--Section Start-->
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img :src="getItem(product.product_image)" alt="img" />
            </div>
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title">
              <span class="subtitle">{{ productData.product_subtitle }}</span>
              <h3 class="title">{{ product.title }}</h3>
            </div>
            <div class="sigma_about-content">
              <p v-for="item in product.desc" :key="item.id">{{ item.row }}</p>
            </div>
          </div>
        </div>
      </div>
      <!--End of row-->
    </div>
  </div>
  <!--Section End-->
</template>

<script>
import data from '../../Data/data.json'
import topBanner from "../../components/innerpagestopbanner/innerTop.vue";
export default {
  components: {
    topBanner,
  },
  data() {
    return {
      productData: '',
      product: ''
    };
  },
  created(){
    this.productData = data.products
    this.product = data.products.main_products[4]
  },
  methods: {
    getItem(pic){
      if(pic){
        return require('../../assets/img/products/md/' + pic)
      } else{
        return null
      }
    }
  }
};
</script>
