<template>
    <topBanner/>
    <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img :src="getImg(specificData.image)" alt="img">
            </div> 
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title"> 
              <h3 class="title">{{ specificData.title }}</h3>
            </div>
            <div class="sigma_about-content">
              <p v-for="item in specificData.desc" :key="item.id">{{ item.row }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row table-sec pt-5">
         <div class="col-lg-12">
          <p class="table-heading">20’ Dry Container</p>
          <table class="table">
            <tr>
              <td class="text-center bg-gray">Dimensions*</td>
              <td class="text-center bg-gray">Length</td>
              <td class="text-center bg-gray">Width</td>
              <td class="text-center bg-gray">Height</td>
            </tr>
            <tr>
              <td class="bg-gray">External</td>
              <td class="text-center">6096 mm</td>
              <td class="text-center">2362 mm</td>
              <td class="text-center">2590 mm</td>
            </tr>
            <tr>
              <td class="bg-gray">Internal</td>
              <td class="text-center">5944 mm</td>
              <td class="text-center">2337 mm</td>
              <td class="text-center">2388 mm</td>
            </tr>
            <tr>
              <td class="bg-gray">Door Openings</td>
              <td></td>
              <td class="text-center">2337 mm</td>
              <td class="text-center">2286 mm</td>
            </tr>
            <tr>
              <td colspan="3" class="text-center bg-gray">Weights*</td> 
              <td rowspan="2" class="text-center bg-gray align-middle">Capacity*</td>
            </tr>
            <tr>
              <td class="text-center bg-gray">Max. Gross</td>
              <td class="text-center bg-gray">Tare</td>
              <td class="text-center bg-gray">Max. Payload</td>
            </tr>
            <tr>
              <td class="text-center">30480 kg</td>
              <td class="text-center">2185 kg</td>
              <td class="text-center">28285 kg</td>
              <td class="text-center">33.2 cub.m</td>
            </tr>
          </table>
         </div>
      </div>

      <div class="row table-sec">
         <div class="col-lg-12">
          <p class="table-heading">40’ Dry Container</p>
          <table class="table">
            <tr>
              <td class="text-center bg-gray">Dimensions*</td>
              <td class="text-center bg-gray">Length</td>
              <td class="text-center bg-gray">Width</td>
              <td class="text-center bg-gray">Height</td>
            </tr>
            <tr>
              <td class="bg-gray">External</td>
              <td class="text-center">12192 mm</td>
              <td class="text-center">2438 mm</td>
              <td class="text-center">2591 mm</td>
            </tr>
            <tr>
              <td class="bg-gray">Internal</td>
              <td class="text-center">12014 mm</td>
              <td class="text-center">2286 mm</td>
              <td class="text-center">2388 mm</td>
            </tr>
            <tr>
              <td class="bg-gray">Door Openings</td>
              <td></td>
              <td class="text-center">2337 mm</td>
              <td class="text-center">2286 mm</td>
            </tr>
            <tr>
              <td colspan="3" class="text-center bg-gray">Weights*</td> 
              <td rowspan="2" class="text-center bg-gray align-middle">Capacity*</td>
            </tr>
            <tr>
              <td class="text-center bg-gray">Max. Gross</td>
              <td class="text-center bg-gray">Tare</td>
              <td class="text-center bg-gray">Max. Payload</td>
            </tr>
            <tr>
              <td class="text-center">30480 kg</td>
              <td class="text-center">3900 kg</td>
              <td class="text-center">26580 kg</td>
              <td class="text-center">67.7 cub.m</td>
            </tr>
          </table>
         </div>
      </div>

      <div class="row table-sec">
         <div class="col-lg-12">
          <p class="table-heading">40’ High Cube Container</p>
          <table class="table">
            <tr>
              <td class="text-center bg-gray">Dimensions*</td>
              <td class="text-center bg-gray">Length</td>
              <td class="text-center bg-gray">Width</td>
              <td class="text-center bg-gray">Height</td>
            </tr>
            <tr>
              <td class="bg-gray">External</td>
              <td class="text-center">12192 mm</td>
              <td class="text-center">2438 mm</td>
              <td class="text-center">2896 mm</td>
            </tr>
            <tr>
              <td class="bg-gray">Internal</td>
              <td class="text-center">11963 mm</td>
              <td class="text-center">2362 mm</td>
              <td class="text-center">2692 mm</td>
            </tr>
            <tr>
              <td class="bg-gray">Door Openings</td>
              <td></td>
              <td class="text-center">2286 mm</td>
              <td class="text-center">2591 mm</td>
            </tr>
            <tr>
              <td colspan="3" class="text-center bg-gray">Weights*</td> 
              <td rowspan="2" class="text-center bg-gray align-middle">Capacity*</td>
            </tr>
            <tr>
              <td class="text-center bg-gray">Max. Gross</td>
              <td class="text-center bg-gray">Tare</td>
              <td class="text-center bg-gray">Max. Payload</td>
            </tr>
            <tr>
              <td class="text-center">30480 kg</td>
              <td class="text-center">4150 kg</td>
              <td class="text-center">26330 kg</td>
              <td class="text-center">76.4 cub.m</td>
            </tr>
          </table>
         </div>
      </div>
      <p class="warning-text">{{ specificData.warning }}</p>
    </div>
  </div>
</template>

<script>
import data from '../../Data/data.json'
import topBanner from '../../components/innerpagestopbanner/innerTop.vue'
export default {
    components: {
        topBanner
    },
    data(){
        return{
          specificData: ''
        }
    },
    created(){
      this.specificData = data.containertypes_specifications.products[0]
    },
    methods: {
      getImg(pic){
        if(pic){
          return require('../../assets/img/typesandspecifications/' + pic)
        } else{
          return null
        }
      }
    }
}
</script>