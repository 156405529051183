<template>
  <topBanner />
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img :src="getImg(specificData.image)" alt="img" />
            </div>
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title">
              <h3 class="title">{{ specificData.title }}</h3>
            </div>
            <div class="sigma_about-content">
              <p v-for="item in specificData.desc" :key="item.id">
                {{ item.row }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row teble-sec pt-5">
        <div class="col-lg-12">
          <div class="feature-content">
            <div class="feature-title">
              {{ specificData.category[0].title }}
            </div>
            <div class="feature-desc">{{ specificData.category[0].row }}</div>
            <p class="table-heading pt-5">20’ Reefer Container</p>
            <table class="table">
              <tr>
                <td class="text-center bg-gray">Dimensions*</td>
                <td class="text-center bg-gray">Length</td>
                <td class="text-center bg-gray">Width</td>
                <td class="text-center bg-gray">Height</td>
              </tr>
              <tr>
                <td class="bg-gray">External</td>
                <td class="text-center">6096 mm</td>
                <td class="text-center">2370 mm</td>
                <td class="text-center">2591 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Internal</td>
                <td class="text-center">5455 mm</td>
                <td class="text-center">2260 mm</td>
                <td class="text-center">2275 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Door Openings</td>
                <td></td>
                <td class="text-center">2237 mm</td>
                <td class="text-center">2260 mm</td>
              </tr>
              <tr>
                <td colspan="3" class="text-center bg-gray">Weights*</td>
                <td rowspan="2" class="text-center bg-gray align-middle">
                  Capacity*
                </td>
              </tr>
              <tr>
                <td class="text-center bg-gray">Max. Gross</td>
                <td class="text-center bg-gray">Tare</td>
                <td class="text-center bg-gray">Max. Payload</td>
              </tr>
              <tr>
                <td class="text-center">27000 kg</td>
                <td class="text-center">3050 kg</td>
                <td class="text-center">23950 kg</td>
                <td class="text-center">28.0 cub.m</td>
              </tr>
            </table>

            <p class="table-heading pt-5">40’ Reefer Container</p>
            <table class="table">
              <tr>
                <td class="text-center bg-gray">Dimensions*</td>
                <td class="text-center bg-gray">Length</td>
                <td class="text-center bg-gray">Width</td>
                <td class="text-center bg-gray">Height</td>
              </tr>
              <tr>
                <td class="bg-gray">External</td>
                <td class="text-center">12192 mm</td>
                <td class="text-center">2438 mm</td>
                <td class="text-center">2591 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Internal</td>
                <td class="text-center">11555 mm</td>
                <td class="text-center">2286 mm</td>
                <td class="text-center">2280 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Door Openings</td>
                <td></td>
                <td class="text-center">2285 mm</td>
                <td class="text-center">2245 mm</td>
              </tr>
              <tr>
                <td colspan="3" class="text-center bg-gray">Weights*</td>
                <td rowspan="2" class="text-center bg-gray align-middle">
                  Capacity*
                </td>
              </tr>
              <tr>
                <td class="text-center bg-gray">Max. Gross</td>
                <td class="text-center bg-gray">Tare</td>
                <td class="text-center bg-gray">Max. Payload</td>
              </tr>
              <tr>
                <td class="text-center">30480 kg</td>
                <td class="text-center">4370 kg</td>
                <td class="text-center">26110 kg</td>
                <td class="text-center">60.2 cub.m</td>
              </tr>
            </table>

            <p class="table-heading pt-5">40’ High Cube Reefer Container</p>
            <table class="table">
              <tr>
                <td class="text-center bg-gray">Dimensions*</td>
                <td class="text-center bg-gray">Length</td>
                <td class="text-center bg-gray">Width</td>
                <td class="text-center bg-gray">Height</td>
              </tr>
              <tr>
                <td class="bg-gray">External</td>
                <td class="text-center">12192 mm</td>
                <td class="text-center">2438 mm</td>
                <td class="text-center">2895 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Internal</td>
                <td class="text-center">11560 mm</td>
                <td class="text-center">2286 mm</td>
                <td class="text-center">2500 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Door Openings</td>
                <td></td>
                <td class="text-center">2286 mm</td>
                <td class="text-center">2478 mm</td>
              </tr>
              <tr>
                <td colspan="3" class="text-center bg-gray">Weights*</td>
                <td rowspan="2" class="text-center bg-gray align-middle">
                  Capacity*
                </td>
              </tr>
              <tr>
                <td class="text-center bg-gray">Max. Gross</td>
                <td class="text-center bg-gray">Tare</td>
                <td class="text-center bg-gray">Max. Payload</td>
              </tr>
              <tr>
                <td class="text-center">30480 kg</td>
                <td class="text-center">4200 kg</td>
                <td class="text-center">26280 kg</td>
                <td class="text-center">60.2 cub.m</td>
              </tr>
            </table>
          </div>

          <div class="feature-content">
            <div class="feature-title">
              {{ specificData.category[1].title }}
            </div>
            <div class="feature-desc">{{ specificData.category[1].row }}</div>
            <p class="table-heading pt-5">20’ Insulated Container</p>
            <table class="table">
              <tr>
                <td class="text-center bg-gray">Dimensions*</td>
                <td class="text-center bg-gray">Length</td>
                <td class="text-center bg-gray">Width</td>
                <td class="text-center bg-gray">Height</td>
              </tr>
              <tr>
                <td class="bg-gray">External</td>
                <td class="text-center">6096 mm</td>
                <td class="text-center">2362 mm</td>
                <td class="text-center">2590 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Internal</td>
                <td class="text-center">5724 mm</td>
                <td class="text-center">2286 mm</td>
                <td class="text-center">2014 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Door Openings</td>
                <td></td>
                <td class="text-center">2286 mm</td>
                <td class="text-center">2067 mm</td>
              </tr>
              <tr>
                <td colspan="3" class="text-center bg-gray">Weights*</td>
                <td rowspan="2" class="text-center bg-gray align-middle">
                  Capacity*
                </td>
              </tr>
              <tr>
                <td class="text-center bg-gray">Max. Gross</td>
                <td class="text-center bg-gray">Tare</td>
                <td class="text-center bg-gray">Max. Payload</td>
              </tr>
              <tr>
                <td class="text-center">24000 kg</td>
                <td class="text-center">2550 kg</td>
                <td class="text-center">21450 mm</td>
                <td class="text-center">26.4 cub. m</td>
              </tr>
            </table>

            <p class="table-heading pt-5">40’ Insulated Container</p>
            <table class="table">
              <tr>
                <td class="text-center bg-gray">Dimensions*</td>
                <td class="text-center bg-gray">Length</td>
                <td class="text-center bg-gray">Width</td>
                <td class="text-center bg-gray">Height</td>
              </tr>
              <tr>
                <td class="bg-gray">External</td>
                <td class="text-center">12192 mm</td>
                <td class="text-center">2438 mm</td>
                <td class="text-center">2591 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Internal</td>
                <td class="text-center">11840 mm</td>
                <td class="text-center">2286 mm</td>
                <td class="text-center">2120 mm</td>
              </tr>
              <tr>
                <td class="bg-gray">Door Openings</td>
                <td></td>
                <td class="text-center">2286 mm</td>
                <td class="text-center">2195 mm</td>
              </tr>
              <tr>
                <td colspan="3" class="text-center bg-gray">Weights*</td>
                <td rowspan="2" class="text-center bg-gray align-middle">
                  Capacity*
                </td>
              </tr>
              <tr>
                <td class="text-center bg-gray">Max. Gross</td>
                <td class="text-center bg-gray">Tare</td>
                <td class="text-center bg-gray">Max. Payload</td>
              </tr>
              <tr>
                <td class="text-center">30480 kg</td>
                <td class="text-center">3850 kg</td>
                <td class="text-center">26630 kg</td>
                <td class="text-center">60.6 cub. m</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <p class="warning-text">{{ specificData.warning }}</p>
    </div>
  </div>
</template>

<script>
import data from "../../Data/data.json";
import topBanner from "../../components/innerpagestopbanner/innerTop.vue";
export default {
  components: {
    topBanner,
  },
  data() {
    return {
      specificData: "",
    };
  },
  created() {
    this.specificData = data.containertypes_specifications.products[7];
  },
  methods: {
    getImg(pic) {
      if (pic) {
        return require("../../assets/img/typesandspecifications/" + pic);
      } else {
        return null;
      }
    },
  },
};
</script>
