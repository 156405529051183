<template>
    <topBanner/>
    <contact/>
</template>

<script>
import topBanner from '../components/innerpagestopbanner/innerTop.vue'
import contact from '../components/contact.vue'
export default {
    components:{
        topBanner,
        contact
    },
    data(){
        return{}
    }
}
</script>