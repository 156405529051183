<template>
    <topBanner/>
    <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img :src="getImg(specificData.image)" alt="img">
            </div> 
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title"> 
              <h3 class="title">{{ specificData.title }}</h3>
            </div>
            <div class="sigma_about-content">
              <p v-for="item in specificData.desc" :key="item.id">{{ item.row }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row table-sec pt-5">
        <div class="col-lg-12">
          <p class="table-heading">Hard Top Container</p>
          <table class="table">
            <tr>
              <td rowspan="2" class="text-center align-middle bg-gray">Size</td>
              <td colspan="3" class="text-center bg-gray">Weights*</td> 
              <td rowspan="2" class="text-center align-middle bg-gray">Capacity*</td>
              <td rowspan="2" class="text-center align-middle bg-gray">Dimensions*</td>
              <td rowspan="2" class="text-center align-middle bg-gray">Length</td>
              <td rowspan="2" class="text-center align-middle bg-gray">Width</td>
              <td rowspan="2" class="text-center align-middle bg-gray">Height</td>
            </tr>
            <tr>
              <td class="text-center bg-gray">Max. Gross</td>
              <td class="text-center bg-gray">Tare</td>
              <td class="text-center bg-gray">Max. Payload</td>
            </tr>
            <tr>
              <td rowspan="4" class="text-center align-middle bg-gray">20'Hard Top Ctnr</td>
              <td rowspan="4" class="text-center align-middle">67200 kg</td>
              <td rowspan="4" class="text-center align-middle">2590 kg</td>
              <td rowspan="4" class="text-center align-middle">27890 kg</td>
              <td rowspan="4" class="text-center align-middle">32.8 cub.m</td>
              <td class="bg-gray">External</td>
              <td class="text-center">6096 mm</td>
              <td class="text-center">2362 mm</td>
              <td class="text-center">2590 mm</td>
            </tr>
            <tr> 
              <td class="bg-gray">Internal</td>
              <td class="text-center">5886 mm</td>
              <td class="text-center">2342 mm</td>
              <td class="text-center">2388 mm</td>
            </tr>
            <tr> 
              <td class="bg-gray">Door Openings</td>
              <td class="text-center"></td>
              <td class="text-center">2336 mm</td>
              <td class="text-center">2276 mm</td>
            </tr>
            <tr> 
              <td class="bg-gray">Roof Openings</td>
              <td class="text-center">5590 mm</td>
              <td class="text-center">2208 mm</td>
              <td class="text-center"></td>
            </tr>
            <tr>
              <td rowspan="4" class="text-center align-middle bg-gray">40’Hard Top Ctnr</td>
              <td rowspan="4" class="text-center align-middle">30480 kg</td>
              <td rowspan="4" class="text-center align-middle">4700 kg</td>
              <td rowspan="4" class="text-center align-middle">25780 kg</td>
              <td rowspan="4" class="text-center align-middle">67.2 cub.m</td>
              <td class="bg-gray">External</td>
              <td class="text-center">12192 mm</td>
              <td class="text-center">2438 mm</td>
              <td class="text-center">2591 mm</td>
            </tr>
            <tr> 
              <td class="bg-gray">Internal</td>
              <td class="text-center">12020 mm</td>
              <td class="text-center">2342 mm</td>
              <td class="text-center">2388 mm</td>
            </tr>
            <tr> 
              <td class="bg-gray">Door Openings</td>
              <td class="text-center"></td>
              <td class="text-center">2336 mm</td>
              <td class="text-center">2292 mm</td>
            </tr>
            <tr> 
              <td class="bg-gray">Roof Openings</td>
              <td class="text-center">11724 mm</td>
              <td class="text-center">2208 mm</td>
              <td class="text-center"></td>
            </tr>
            <tr>
              <td rowspan="4" class="text-center align-middle bg-gray">40’High Cube Hard Top Ctnr</td>
              <td rowspan="4" class="text-center align-middle">30480 kg</td>
              <td rowspan="4" class="text-center align-middle">4900 kg</td>
              <td rowspan="4" class="text-center align-middle">25580 kg</td>
              <td rowspan="4" class="text-center align-middle">75.8 cub.m</td>
              <td class="bg-gray">External</td>
              <td class="text-center">12192 mm</td>
              <td class="text-center">2438 mm</td>
              <td class="text-center">2896 mm</td>
            </tr>
            <tr> 
              <td class="bg-gray">Internal</td>
              <td class="text-center">12020 mm</td>
              <td class="text-center">2342 mm</td>
              <td class="text-center">2693 mm</td>
            </tr>
            <tr> 
              <td class="bg-gray">Door Openings</td>
              <td class="text-center"></td>
              <td class="text-center">2336 mm</td>
              <td class="text-center">2597 mm</td>
            </tr>
            <tr> 
              <td class="bg-gray">Roof Openings</td>
              <td class="text-center">11724 mm</td>
              <td class="text-center">2208 mm</td>
              <td class="text-center"></td>
            </tr>
          </table>
        </div>
      </div>
      <p class="warning-text">{{ specificData.warning }}</p>
    </div>
  </div>
</template>

<script>
import data from '../../Data/data.json'
import topBanner from '../../components/innerpagestopbanner/innerTop.vue'
export default {
    components: {
        topBanner
    },
    data(){
        return{
          specificData: ''
        }
    },
    created(){
      this.specificData = data.containertypes_specifications.products[2]
    },
    methods: {
      getImg(pic){
        if(pic){
          return require('../../assets/img/typesandspecifications/' + pic)
        } else{
          return null
        }
      }
    }
}
</script>