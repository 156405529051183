<template>
  <div class="section section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="sigma_info style-19">
            <div class="sigma_info-title">
              <span class="sigma_info-icon bg-primary-1 text-white">
                <i class="flaticon-address"></i>
              </span>
              <h5>Our Address</h5>
            </div>
            <div class="sigma_info-description">
              <p>{{ contactData.comapny_name }},</p>
              <!-- <p>
                {{ contactData.address }}
              </p> -->
              <ul
                v-for="item in contactData.address_line_by_line"
                :key="item.id"
              >
                <li>{{ item.row }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="sigma_info style-19">
            <div class="sigma_info-title">
              <span class="sigma_info-icon bg-primary-1 text-white">
                <i class="flaticon-call"></i>
              </span>
              <h5>Our Phone</h5>
            </div>
            <div class="sigma_info-description">
              <p>
                <span class="contactPerson">Land Line Number:</span>
                {{ contactData.landlineno }}
              </p>
              <p v-for="item in contactData.contact_person" :key="item.id">
                <span class="contactPerson">{{ item.name }}: </span
                >{{ item.mobileno }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="sigma_info style-19">
            <div class="sigma_info-title">
              <span class="sigma_info-icon bg-primary-1 text-white">
                <i class="flaticon-mail"></i>
              </span>
              <h5>Our Email</h5>
            </div>
            <div class="sigma_info-description">
              <p>
                {{ contactData.mailid }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="section sigma_contact bg-cover bg-center"
    style="background-color: var(--thm-base)"
  >
    <div class="container">
      <div class="section-title centered"></div>
    </div>
  </div>
  <div class="sigma_contact-map is-absolute">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <iframe
            :src="contactData.location"
            width="600"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="container">
      <div class="section-title centered">
        <span class="subtitle">Reach Out</span>
        <h3 class="title">Send a Message.</h3>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8">
          <form
            id="contact-form2"
            @submit="sendmail2()"
            action="javascript:void(0)"
            class="contact-form"
            method="POST"
          >
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>First Name</label>
                  <input type="text" name="fname" id="fname" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Last Name</label>
                  <input type="text" name="lname" id="lname" />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Email <i class="required">*</i> </label>
                  <input type="email" name="email" id="email"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label>Message <i class="required">*</i> </label>
                  <textarea name="message" id="message" rows="5"> </textarea>
                </div>
              </div>
              <div class="col-12">
                <button type="submit" class="btn-block">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { Email } from "../assets/js/smtp.js";
import swal from "sweetalert2";
window.Swal = swal;
import data from "../Data/data.json";
export default {
  data() {
    return {
      contactData: "",
    };
  },
  created() {
    this.contactData = data.company_detailes;
  },
  methods: {
    sendmail2() {
      // alert("function called");
      var fname = $("#fname").val();
      var lname = $("#lname").val();
      var email = $("#email").val(); 
      var message = $("#message").val(); 
      var Body =
        "First Name: " +
        fname +
        "<br>Last Name: " +
        lname +
        "<br>Email: " +
        email + 
        "<br>Message: " +
        message;

      Email.send({  
        // Host: "smtp.elasticemail.com",
        // Host: "smtp.sendgrid.net",
        // Port: 25,
        // Username: "apikey",
        // Password: "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",
        // To: "info@boxtradecontainer.com",
        SecureToken : "742536e6-327b-466a-b830-6e520e223502",
        To: "info@boxtradecontainer.com",
        From: "boxtradecontainer@gmail.com",
        Subject: "New message on contact from " + fname,
        Body: Body,
      }).then((message) => {
        if (message == "OK") {
          new swal(
            "Thank You For Message Us!",
            "We will contact you as soon as possible!",
            "success"
          );
          document.getElementById("contact-form2").reset();
        } else {
          console.error(message);
          // return new Swal(
          new swal(
            "Oops!",
            "Something went wrong, please try again!",
            "error"
          );
          document.getElementById("contact-form2").reset();
        }
      });
    },
  },
};
</script>

<style>
.contactPerson {
  color: var(--thm-base);
  font-weight: bold;
}
</style>
