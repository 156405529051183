<template>
    <topBanner/>
    <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img :src="getImg(specificData.image)" alt="img">
            </div> 
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title"> 
              <h3 class="title">{{ specificData.title }}</h3>
            </div>
            <div class="sigma_about-content">
              <p v-for="item in specificData.desc" :key="item.id">{{ item.row }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row table-sec pt-5">
        <div class="col-lg-12">
          <p class="table-heading">20’ Flat Rack Container, 40’ Flat Rack Container, 40’ High Cube Flat Rack Container</p>
          <table class="table">
            <tr>
              <td rowspan="2" class="text-center align-middle bg-gray">Size</td>
              <td colspan="3" class="text-center bg-gray">Weights*</td>
              <td colspan="3" class="text-center bg-gray">Internal Dimensions*</td>
            </tr>
            <tr>
              <td class="text-center bg-gray">Max. Gross</td>
              <td class="text-center bg-gray">Tare</td>
              <td class="text-center bg-gray">Max. Payload</td>
              <td class="text-center bg-gray">Length</td>
              <td class="text-center bg-gray">Width</td>
              <td class="text-center bg-gray">Height</td>
            </tr>
            <tr>
              <td class="text-center bg-gray">20’ Flat Rack Container</td>
              <td class="text-center">34000 kg</td>
              <td class="text-center">2740 kg</td>
              <td class="text-center">31260 kg</td>
              <td class="text-center">6038 mm</td>
              <td class="text-center">2438 mm</td>
              <td class="text-center">2213 mm</td>
            </tr>
            <tr>
              <td class="text-center bg-gray">40’ Flat Rack Container</td>
              <td class="text-center">30480 kg</td>
              <td class="text-center">4200 kg</td>
              <td class="text-center">26280 kg</td>
              <td class="text-center">12086 mm</td>
              <td class="text-center">2224 mm</td>
              <td class="text-center">1981 mm</td>
            </tr>
            <tr>
              <td class="text-center bg-gray">40’ High Cube Flat Rack Container</td>
              <td class="text-center">45000 kg</td>
              <td class="text-center">5700 kg</td>
              <td class="text-center">39300 kg</td>
              <td class="text-center">12060 mm</td>
              <td class="text-center">2365 mm</td>
              <td class="text-center">2245 mm</td>
            </tr>
          </table>
        </div>
      </div>
      <p class="warning-text">{{ specificData.warning }}</p>
    </div>
  </div>
</template>

<script>
import data from '../../Data/data.json'
import topBanner from '../../components/innerpagestopbanner/innerTop.vue'
export default {
    components: {
        topBanner
    },
    data(){
        return{
          specificData: ''
        }
    },
    created(){
      this.specificData = data.containertypes_specifications.products[4]
    },
    methods: {
      getImg(pic){
        if(pic){
          return require('../../assets/img/typesandspecifications/' + pic)
        } else{
          return null
        }
      }
    }
}
</script>